enum LocalStorageKeys {
  SIGNATURE_COMPANY_NAME = '@sign:signature:companyName',
  SIGNATURE_UUID = '@sign:signature:uuid',
  ACCOUNT_KEY = '@sign:account',
  COLOR = '@sign:color',
  COLOR_PRIMARY = '@sign:colorPrimary',
  COLOR_SECONDARY = '@sign:colorSecundary',
  LOGO = '@sign:logo',
  ONE_COMPANY = '@sign:oneCompany',
}

export default LocalStorageKeys;
