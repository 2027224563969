import { yupResolver } from '@hookform/resolvers/yup';
import BlueGenericButton from 'components/blue-generic-button/BlueGenericButton';
import CardShowContract from 'components/card-show-contract/CardShowContract';
import ErrorMessage from 'components/error-message/ErrorMessage';
import InputToken from 'components/input-token/InputToken';
import PageTitleSection from 'components/page-title-section/PageTitleSection';
import { addSeconds, format } from 'date-fns';
import useHelperNavigation, { routeNextStepSignature } from 'hooks/useHelperNavigation';
import { isEmpty } from 'lodash';
import AuthenticationTypes from 'model/enums/authentication-type';
import SignatureMethods from 'model/enums/signature-methods';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/storeConfig';
import contractService from 'services/contract-service';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { addSignatureData } from '../../redux/slice/data-signing-contract';
import {
  StyledButton,
  StyledCardShowContractContainer,
  StyledContainer,
  StyledInnerContainer,
  StyledTokenContainer,
  StylesTerms,
} from './styles';
import companyService from 'services/company-service';
import { ISubscriptionData } from 'model/contract-details';

interface IForm {
  firstDigit: string;
  secondDigit: string;
  thirdDigit: string;
  fourthDigit: string;
  fifthDigit: string;
  sixthDigit: string;
}

const InsertToken = ({ t }: WithTranslation) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { contract, signature } = useSelector((state: RootState) => state);

  let timerSeconds = 180;

  const { errorMessageSignContract, isLoadingSendingContractSignature, sendSignature } = useHelperNavigation();

  const [nextInputFocus, setNextInputFocus] = useState<string>('firstDigit');
  const [timerResendToken, setTimerResendToken] = useState<string>('');

  const schema = yup.object({
    firstDigit: yup.string().required(),
    secondDigit: yup.string().required(),
    thirdDigit: yup.string().required(),
    fourthDigit: yup.string().required(),
    fifthDigit: yup.string().required(),
    sixthDigit: yup.string().required(),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const inputValues = methods.getValues();

  useEffect(() => {
    document.getElementById(nextInputFocus)?.focus();
  }, [nextInputFocus]);

  useEffect(() => handleStartTimer(), []);

  useEffect(() => {
    const fields = methods.watch(value => {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          if (value[key].length === 6) {
            const currentInputValue = value[key].split('');
            methods.setValue('firstDigit', currentInputValue[0]);
            methods.setValue('secondDigit', currentInputValue[1]);
            methods.setValue('thirdDigit', currentInputValue[2]);
            methods.setValue('fourthDigit', currentInputValue[3]);
            methods.setValue('fifthDigit', currentInputValue[4]);
            methods.setValue('sixthDigit', currentInputValue[5]);

            return;
          }

          if (isEmpty(value[key])) {
            return setNextInputFocus(key);
          }
        }
      }
    });

    return () => fields.unsubscribe();
  }, [methods.watch]);

  const enterButtonListener = () => {
    return document?.addEventListener('keydown', e => {
      if (e.key === 'Enter' && methods.formState.isValid) {
        handleFormSubmit(inputValues);
      }
    });
  };

  const handleFormSubmit = (data: IForm) => {
    const nextStep = routeNextStepSignature(SignatureMethods.TOKEN, contract.contracDetails?.signatureMethods);
    const token = Object.values(data).join('');
    document.removeEventListener('keydown', enterButtonListener);
    dispatch(addSignatureData({ tokenSignature: { token } }));

    if (nextStep != null) {
      navigate(nextStep);
    } else {
      const isEnvelopeSignature = contract.contracDetails?.envelope != null;
      const updatedSubscriptionData: ISubscriptionData = { ...signature.subscriptionData, tokenSignature: { token } };

      sendSignature(updatedSubscriptionData, isEnvelopeSignature);
    }
  };

  const handleResendToken = () => {
    if (contract.contracDetails?.batchSignatureData != null) {
      handleStartTimer();
      companyService.sendBatchSignatureTokenToEmail(contract.contracDetails.batchSignatureData);
    } else {
      if (contract.contracDetails?.subscriptionKey != null) {
        handleStartTimer();
        contractService.sendTokenToEmail(contract.contracDetails.subscriptionKey);
      }
    }
  };

  const handleStartTimer = () => {
    setTimerResendToken('');

    const startTimerResendToken = setInterval(() => {
      timerSeconds--;
      handleTimer(timerSeconds);

      if (timerSeconds <= 0) {
        clearInterval(startTimerResendToken);
      }
    }, 1000);
  };

  const handleTimer = (seconds: number) => {
    const helperDate = addSeconds(new Date(0), seconds);
    setTimerResendToken(format(helperDate, 'mm:ss'));
  };

  return (
    <StyledContainer>
      <PageTitleSection
        signatureMethods={contract.contracDetails?.signatureMethods}
        currentSignatureMethod={SignatureMethods.TOKEN}
        i18nKeyTitle={'insertToken.title'}
        i18nKeySubtitle={
          contract.contracDetails?.authentication === AuthenticationTypes.SMS ? 'insertToken.subtitlePhone' : 'insertToken.subtitle'
        }
        highlightedText={
          contract.contracDetails?.authentication === AuthenticationTypes.SMS
            ? StringUtils.cellMask(contract.contracDetails.phone)
            : contract.contracDetails?.email
        }
        textAlign="left"
      />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
          <StyledTokenContainer>
            <InputToken name="firstDigit" maxLength={6} />
            <InputToken name="secondDigit" />
            <InputToken name="thirdDigit" />
            <InputToken name="fourthDigit" />
            <InputToken name="fifthDigit" />
            <InputToken name="sixthDigit" />

            <ErrorMessage text={errorMessageSignContract} style={{ position: 'absolute', bottom: '-20px', left: 0 }} />
          </StyledTokenContainer>

          {timerResendToken === '00:00' ? (
            <>
              {t('insertToken.notReceiveToken')}
              <StyledButton onClick={() => handleResendToken()}>{t('global.button.resendToken')}</StyledButton>
            </>
          ) : (
            <Trans i18nKey="insertToken.sendSuccess">
              <span className="highlighted">{{ timer: timerResendToken }}</span>
            </Trans>
          )}

          <BlueGenericButton
            style={{ marginTop: '25px' }}
            isLoading={isLoadingSendingContractSignature}
            disabled={!methods.formState.isValid}
          >
            {t('global.button.confirm')}
          </BlueGenericButton>
        </form>
      </FormProvider>

      <StylesTerms>
        <Trans i18nKey="insertToken.agreeTerms">
          <a href="https://esparta.s3.amazonaws.com/Termos+de+Uso+-+Sign.CreditFlow.pdf" target="_blank" className="bold-text">
            {{ terms: t('insertToken.terms') }}
          </a>
        </Trans>
        <Trans i18nKey="insertToken.agreePrivacy">
          <a
            href="https://esparta.s3.amazonaws.com/POLÍTICA+DE+PRIVACIDADE+–+LGPD+-+Sign.CreditFlow.pdf"
            target="_blank"
            className="bold-text"
          >
            {{ privacy: t('insertToken.privacy') }}
          </a>
        </Trans>
      </StylesTerms>
      {contract.contracDetails?.batchSignatureData == null && (
        <StyledInnerContainer>
          <StyledCardShowContractContainer>
            <CardShowContract />
          </StyledCardShowContractContainer>
        </StyledInnerContainer>
      )}
    </StyledContainer>
  );
};

export default withTranslation()(InsertToken);
